
import React from 'react'
import Moment from 'moment';
import { Grid } from '@mui/material';
import LineChart from '../../../common/chart/line-chart';
import { IRecord } from '../../../models/common.model';

type PropsType = {
  records: IRecord[]
}

const BodyStatsAnalyze: React.FC<PropsType> = ({ records }) => {
  Moment.locale('en');
  return (
    <Grid container style={{ display: 'flex' }}>
      <Grid item xs={6} style={{ height: '30rem' }}>
        <LineChart
          title='Nhịp tim'
          labels={records?.map((record) => Moment(record?.date_record).format('MM-DD-YYYY'))}
          data={records?.map((record) => record?.bpm)}
          borderColor="rgb(255, 99, 132)"
          backgroundColor='rgba(255, 99, 132, 0.5)'
          pointBorderColor='rgb(255, 99, 132)'
          pointRadius={6}
        />
      </Grid>
      <Grid item xs={6} style={{ height: '30rem' }}>
        <LineChart
          title='Nồng độ oxi'
          labels={records?.map((record) => Moment(record?.date_record).format('MM-DD-YYYY'))}
          data={records?.map((record) => record?.spo2)}
          borderColor="rgb(53, 162, 235)"
          backgroundColor='rgb(53, 162, 235, 0.5)'
          pointBorderColor='rgb(53, 162, 235)'
          pointRadius={6}
        />
      </Grid>
      <Grid item xs={6} style={{ height: '30rem' }}>
        <LineChart
          title='Nhiệt độ cơ thể'
          labels={records?.map((record) => Moment(record?.date_record).format('MM-DD-YYYY'))}
          data={records?.map((record) => record?.tem)}
          borderColor="rgba(49, 158, 60)"
          backgroundColor='rgba(49, 158, 60, 0.5)'
          pointBorderColor='rgba(49, 158, 60)'
          pointRadius={6}
        />
      </Grid>
    </Grid>
  )
}

export default BodyStatsAnalyze