import { Button, CircularProgress, Divider, Grid, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import Moment from 'moment';
import { useEffect, useState } from "react";
import ReactPlayer from 'react-player'
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import ReactAudioPlayer from 'react-audio-player';
import styles from './styles.module.scss'
import { Props } from "ahooks/lib/useControllableValue";
import LineChart from "../chart/line-chart";
import { IRecord } from "../../models/common.model";

import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import EarbudsIcon from '@mui/icons-material/Earbuds';
import { useRequest } from 'ahooks';
import { getFileFromDirectory } from "../../api/medical-record";

interface PropsType {
  record: IRecord
}

const BodyStats: React.FC<PropsType> = ({ record }: Props) => {
  Moment.locale('en');
  const baseUrl = process.env.REACT_APP_BASE_URL
  const [videoName, setVideoName] = useState<string>('')
  const [audioName, setAudioName] = useState<string>('')
  const [listImages, setListImages] = useState<string[]>([])

  const getImagesFromDirectory = useRequest(getFileFromDirectory, {
    manual: true,
    ready: record?.recordId,
    refreshDeps: [record],
    onSuccess: (data) => {
      if (data) {
        console.log(data)
        setListImages(data)
      }
    },
  })

  useEffect(() => {
    getImagesFromDirectory.run(record?.recordId)
  }, [record])

  const handleChangeVideo = ( event: React.MouseEvent<HTMLElement>, value: string,) => {
    setVideoName(value)
  }
  const handleChangeAudio = ( event: React.MouseEvent<HTMLElement>, value: string,) => {
    setAudioName(value)
  }

  const exportFilePDF = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const input = document.getElementById('componentToPrint')!;
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf?.addImage(imgData, 'JPEG', 0, 0, 0, 0);
        // pdf.output('dataurlnewwindow');
        pdf.save("download.pdf");
      })
    ;
  }
  return (
    <>
      <Button onClick={() => exportFilePDF()}>
        Export
      </Button>
      <Grid container rowSpacing={2} className={styles.container} id="componentToPrint">
        <Grid item xs={4} className={styles.itemContainer}>
          <Typography style={{ display: 'inline-flex' }}>
            <span style={{ marginRight: '0.5rem' }}>
              <DateRangeIcon />
            </span>
            Ngày đo: 
            {' '}
            {Moment(record?.date_record).format('DD-MM-YYYY')}
          </Typography>
          <Typography style={{ display: 'inline-flex' }}>
            <span style={{ marginRight: '0.5rem' }}>
              <MonitorHeartIcon />
            </span>
            Nhịp tim:
            {' '}
            {record?.bpm}
          </Typography>
          <Typography style={{ display: 'inline-flex' }}>
            <span style={{ marginRight: '0.5rem' }}>
              <DirectionsRunIcon />
            </span>
            Nồng độ oxy trong máu:
            {' '}
            {record?.spo2}
          </Typography>
          <Typography style={{ display: 'inline-flex' }}>
            <span style={{ marginRight: '0.5rem' }}>
              <DeviceThermostatIcon />
            </span>
            Nhiệt độ cơ thể: 
            {' '}
            {record?.tem}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <LineChart
            title="Chỉ số ECG"
            labels={record?.ecg?.map((item: number, index: number) => (index/100)*4)}
            data={record?.ecg}
          />
        </Grid>
        {getImagesFromDirectory.loading ? (
          <div style={{ margin: 'auto', marginTop: '20rem' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Divider style={{ width: '100%', marginTop: '3rem', marginBottom: '2rem'}} />
            <Grid item xs={12}>
              {listImages?.map((url, index) => (
                <img src={url} alt="Davinci" key={index} />
              ))}
            </Grid>
          </>
        )}
        <Divider style={{ width: '100%', marginTop: '3rem', marginBottom: '2rem'}} />
        <Grid item xs={12}>
          <ToggleButtonGroup
            value={videoName}
            exclusive
            onChange={handleChangeVideo}
            aria-label="text alignment"
          >
            {record?.ears_videos && (
              <ToggleButton value={record?.ears_videos} aria-label="left aligned">
                <SlowMotionVideoIcon />
                Nội soi tai
              </ToggleButton>
            )}
            {record?.mouth_videos && (
              <ToggleButton value={record?.mouth_videos} aria-label="left aligned">
                <SlowMotionVideoIcon />
                Nội soi miệng
              </ToggleButton>
            )}
            {record?.nose_videos && (
              <ToggleButton value={record?.nose_videos} aria-label="left aligned">
                <SlowMotionVideoIcon />
                Nội soi mũi
              </ToggleButton>
            )}
            {record?.another_videos && (
              <ToggleButton value={record?.another_videos} aria-label="left aligned">
                <SlowMotionVideoIcon />
                Nội soi các bộ phận khác
              </ToggleButton>
            )}
          </ToggleButtonGroup>
          {videoName && (
            <div style={{position: 'relative', top: '5%', display: 'flex', justifyContent: 'center'}}>
              <ReactPlayer
                playing
                controls
                url={`${baseUrl}/api/record/get-file/?path=${videoName}`}
              />
            </div>
          )}
        </Grid>
        <Divider style={{ width: '100%', marginTop: '3rem', marginBottom: '2rem'}} />
        <Grid item xs={12}>
          <ToggleButtonGroup
            value={audioName}
            exclusive
            onChange={handleChangeAudio}
            aria-label="text alignment"
          >
            {record?.heart_audio && (
              <ToggleButton value={record?.heart_audio} aria-label="left aligned">
                <EarbudsIcon />
                Âm thanh tim
              </ToggleButton>
            )}
            {record?.lung_audio && (
              <ToggleButton value={record?.lung_audio} aria-label="left aligned">
                <EarbudsIcon />
                Âm thanh phổi
              </ToggleButton>
            )}
          </ToggleButtonGroup>
          {audioName && (
            <div style={{position: 'relative', top: '5%', display: 'flex', justifyContent: 'center'}}>
              <ReactAudioPlayer
                autoPlay
                controls
                src={`${baseUrl}/api/record/get-file/?path=${audioName}`}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default BodyStats