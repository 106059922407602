import { Alert, Box, Button, Grid, Modal, Snackbar, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { IUpdateProfile } from '../../api/profile'
import { updateProfile } from '../../api/profile'
import { getUser } from '../../api/auth'
import styles from './styles.module.scss'
import { User } from '../../models/user.model';
import UpdateInformation from './update-information';
import ConfirmInformation from './confirm-information';
import ConfirmIdentifierCode from './confirm-identifier-code';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { useRequest } from 'ahooks';

type PropsType = object

const ProfileManagement: React.FC<PropsType> = () => {
  const token = localStorage.getItem('id token')
  const theme = useTheme();
  const [formFields, setFormFields] = useState<IUpdateProfile>()

  const [user, setUser] = useState<User>()

  const [alert, setAlert] = useState({
    severity: "",
    message: ""
  })
  const [openAlert, setOpenAlert] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [openConfirmSave, setOpenConfirmSave] = useState<boolean>(false)

  const stepper = [
    {
      label: 'Đăng kí thông tin',
      component: <UpdateInformation formFields={formFields || {}} setFormFields={setFormFields} />,
    },
    {
      label: ' Xác nhận thông tin đăng kí',
      component: <ConfirmInformation formFields={formFields || {}} />,
    },
    {
      label: 'Xác nhận mã định danh',
      component: <ConfirmIdentifierCode />,
    },
  ];

  const getUserByToken = useRequest(getUser, {
    manual: true,
    refreshDeps: [token],
    onSuccess: (data) => {
      if (data.user) {
        setUser(data.user)
      } else {
        setAlert({
          severity: 'error',
          message: 'Đã xảy ra lỗi khi đăng nhập'
        })
        setOpenAlert(true)
      }
    },
    onError: (err) => {
      setAlert({
        severity: 'error',
        message: JSON.stringify(err)
      })
    }
  })

  useEffect(() => {
    getUserByToken.run(token || '')
  }, [token])

  useEffect(() => {
    if (user) {
      setFormFields({...formFields,
        name: user?.name || '',
        email: user?.email,
        cccd: user?.cccd,
        address: user?.address,
        contactNumber: user?.contactNumber,
        note: user?.note,
        plan: user?.plan || 'F',
        sex: user?.sex,
        dob: user?.dob,
      })
    }
  }, [user, setUser, token])

  const handleNext = () => {
    if (activeStep === 1) {
      setOpenConfirmSave(true)
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }

  const handleBack = () => {
    if (activeStep < 2) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  }

  const getUserData = () => {
    if (user?.email) {
      updateProfile(user.email, formFields || {}, (data, error) => {
        if (data) {
          setOpenAlert(true)
          setAlert({
            severity: "success",
            message: "Cập nhật thành công!",
          })
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setOpenConfirmSave(false)
          setUser(data)
        }
        if (error) {
          setOpenAlert(true)
          setAlert({
            severity: "error",
            message: error as string,
          })
        }
      })
    }
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  }

  return (
    <React.Fragment>
      <Snackbar open={openAlert} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity={alert.severity === "success" ? "success" : "error"} onClose={handleClose} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
      <Grid container>
        <Grid item xs={12}>
          {(user?.name !== '') && (user?.address !== '') && (user?.contactNumber !== '') ? (
            <ConfirmInformation formFields={user || {}} />
          ) : (
            <>
              <Box className={styles.stepContainer}>
                {stepper[activeStep].component}
              </Box>
              <MobileStepper
                variant="dots"
                steps={3}
                activeStep={activeStep}
                sx={{ maxWidth: 400, backgroundColor: 'transparent', position: 'inherit', margin: 'auto' }}
                nextButton={
                  <Button size="small" onClick={handleNext} disabled={activeStep === 2}>
                    Next
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button size="small" onClick={handleBack} disabled={activeStep === 0 && alert.severity === 'success'}>
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Back
                  </Button>
                }
              />
            </>
          )}
        </Grid>
      </Grid>
      <Modal
        open={openConfirmSave}
        onClose={() => setOpenConfirmSave(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box className={styles.modal}>
          <Typography>
            Thông tin bạn vừa nhập sẽ được lưu và không thể thay đổi! Bạn có chắc chắn muốn lưu?
          </Typography>
          <Button startIcon={<SaveAltIcon />} onClick={() => getUserData()} className={styles.btn}>
            Lưu
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  )
}

export default ProfileManagement