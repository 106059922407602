import { routerType } from "../types/router.types";
import LoginForm from "../pages/login";
import RegisterForm from "../pages/register";
import Home from "../pages/home"
import Profile from "../pages/profile-management";
import CheckHealth from '../pages/record-history/index';
import RecordAnalyze from "../pages/record-analyze";
import MemberManagement from "../pages/member-management";
import UserInformation from "../pages/profile-management/user-information"
import GoogleSuspense from '../pages/google-suspense/index';

const PagesData: routerType[] = [
  {
    path: "/",
    element: <Home />,
    title: "Trang chủ"
  },
  {
    path: "/google",
    element: <GoogleSuspense />,
    title: "Google"
  },
  {
    path: "/dang-ki",
    element: <RegisterForm />,
    title: "Dang Ki"
  },
  {
    path: "/dang-nhap",
    element: <LoginForm />,
    title: "Dang nhap"
  },
  {
    path: '/dang-ki-thong-tin',
    element: <Profile />,
    title: 'Dang ki thong tin'
  },
  {
    path: '/thong-tin-nguoi-dung',
    element: <UserInformation />,
    title: 'Thong tin nguoi dung'
  },
  {
    path: '/check',
    element: <CheckHealth />,
    title: 'Check health'
  },
  {
    path: '/analyze',
    element: <RecordAnalyze />,
    title: 'Analyze'
  },
  {
    path: '/quan-li-thanh-vien',
    element: <MemberManagement />,
    title: 'Member Management'
  }
];

export default PagesData;
