import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, } from 'chart.js'
import { useNavigate } from 'react-router-dom';
import { Alert, Grid, Snackbar, Box, CircularProgress, MobileStepper, Button, useTheme } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { User } from '../../models/user.model'
import { getUser } from '../../api/auth'
import { getRecords } from '../../api/medical-record';
import { useRequest } from 'ahooks';
import BodyStats from '../../common/body-stats';
import { IMember } from '../../models/member.model';
import { getMembers } from '../../api/member-management';
import styles from './styles.module.scss'
import EmptyBoxIcon from '../../images/Icon/empty-box-icon';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { IRecord } from '../../models/common.model';

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
)

type PropsType = object

const CheckHealth: React.FC<PropsType> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [user, setUser] = useState<User>()
  const [alert, setAlert] = useState({
    severity: "info",
    message: "",
  })
  const [openAlert, setOpenAlert] = useState(false)
  const [healthParameters, setHealthParameters] = useState<IRecord[]>([])
  const [members, setMembers] = useState<IMember[]>([])
  const [tab, setTab] = useState<string>(user?.userId || "");
  const [activeStep, setActiveStep] = useState(0)

  const token = localStorage.getItem('id token')

  const getAllMembers = useRequest(getMembers, {
    manual: true,
    onSuccess: (data) => {
      setMembers(data)
    }
  })

  const getRecordsByUser = useRequest(getRecords, {
    manual: true,
    onSuccess: (data) => {
      setHealthParameters(data)
    },
    onError: (err) => {
      setAlert({
        severity: 'error',
        message: JSON.stringify(err)
      })
      setOpenAlert(true)
    }
  })

  const getUserByToken = useRequest(getUser, {
    refreshDeps: [token],
    onSuccess: (data) => {
      if (data.user) {
        setUser(data.user)
      } else {
        setAlert({
          severity: 'error',
          message: 'Đã xảy ra lỗi khi đăng nhập'
        })
        setOpenAlert(true)
      }
    },
    onError: (err) => {
      setAlert({
        severity: 'error',
        message: JSON.stringify(err)
      })
      setOpenAlert(true)
      navigate('/dang-nhap')
    }
  })

  useEffect(() => {
    getUserByToken.run(token || '')
    getAllMembers.run()
  }, [token])

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  }

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue)
    getRecordsByUser.run(newValue)
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  return (
    <React.Fragment>
        <Box  sx={{ display: 'flex', width: '-webkit-fill-available' }}>
          <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
            <Alert onClose={handleClose} severity={alert.severity === 'success'? 'success':'error'} sx={{ width: '100%' }}>
              {alert.message}
            </Alert>
          </Snackbar>
          <Grid item xs={12} className={styles.styleItemGrid}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              {getAllMembers.loading ? (
                <CircularProgress color="inherit" />
              ) : (
                <TabContext value={tab || ""}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="Member'record management">
                      {members?.map((m) => (
                        <Tab key={m?.userId} label={m?.userId === user?.userId ? `${m?.name} (owner)` : m?.name} value={m?.userId} />
                      ))}
                    </TabList>
                  </Box>
                  {members?.map((m) => (
                    <TabPanel key={m?.userId} value={m?.userId || ''}>
                      {getRecordsByUser.loading ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          {healthParameters.length === 0 ? (
                            <EmptyBoxIcon />
                          ) : (
                            <>
                              <Grid item xs={12}>
                                <BodyStats key={activeStep} record={healthParameters[activeStep]} />
                              </Grid>
                              <Grid item xs={12}>
                                <MobileStepper
                                  variant="dots"
                                  steps={healthParameters.length}
                                  activeStep={activeStep}
                                  sx={{ maxWidth: 400, backgroundColor: 'transparent', position: 'inherit', margin: 'auto' }}
                                  nextButton={
                                    <Button size="small" onClick={handleNext} disabled={activeStep === healthParameters.length -1}>
                                      Next
                                      {theme.direction === 'rtl' ? (
                                        <KeyboardArrowLeft />
                                      ) : (
                                        <KeyboardArrowRight />
                                      )}
                                    </Button>
                                  }
                                  backButton={
                                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                      {theme.direction === 'rtl' ? (
                                        <KeyboardArrowRight />
                                      ) : (
                                        <KeyboardArrowLeft />
                                      )}
                                      Back
                                    </Button>
                                  }
                                />
                              </Grid>
                            </>
                          )}
                        </Grid>
                      )}
                    </TabPanel>
                  ))}
                </TabContext>
              )}
            </Box>
          </Grid>
        </Box>
    </React.Fragment>
  )
}

export default CheckHealth