import React, { useEffect, useState } from 'react'
import { Box, Grid, Stack, Typography, Paper } from '@mui/material'
import FingerprintIcon from '@mui/icons-material/Fingerprint'
import { styled } from '@mui/material/styles'
import styles from './styles.module.scss'
import { getUser } from '../../../api/auth'
import { User } from '../../../models/user.model'
import { useRequest } from 'ahooks'

type PropsType = object

const ConfirmIdentifierCode: React.FC<PropsType> = () => {
  const token = localStorage.getItem('id token')
  const [user, setUser] = useState<User>()
  const getUserByToken = useRequest(getUser, {
    manual: true,
    refreshDeps: [token],
    onSuccess: (data) => {
      if (data.user) {
        setUser(data.user)
      }
    },
  })

  useEffect(() => {
    getUserByToken.run(token || '')
  }, [token])
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'left',
    minWidth: '22rem',
    margin: '1rem',
  }));
  return (
    <Grid container className={styles.container}>
      <Box
        className={styles.subContainer}
        sx={{
          '& .MuiTextField-root': { borderBottom: '1px solid #603c81', width: '30rem' },
        }}
      >
        <Typography variant="h5" className={styles.title}>Xác nhận mã định danh</Typography>
        <Stack spacing={{ xs: 3, sm: 2 }} className={styles.infoBox} direction="row" useFlexGap flexWrap="wrap">
          <Item>
            <FingerprintIcon />
            <Typography className={styles.textInfo}>Mã định danh: {user?.userId}</Typography>
          </Item>
        </Stack>
      </Box>
    </Grid>
  )
}

export default ConfirmIdentifierCode