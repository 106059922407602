import { Alert, Box, Button, CircularProgress, Grid, Paper, Snackbar, Stack, Typography } from '@mui/material'
import { useRequest } from 'ahooks'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { getUser } from '../../api/auth'
import styles from './styles.module.scss'
import { User } from '../../models/user.model';
import { addMember, getMembers, IMemberParams } from '../../api/member-management'
import { IMember } from '../../models/member.model';

import BadgeIcon from '@mui/icons-material/Badge';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FemaleIcon from '@mui/icons-material/Female';
import MaleIcon from '@mui/icons-material/Male';
import HomeIcon from '@mui/icons-material/Home';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddIcon from '@mui/icons-material/Add';
import CreateMember from './create-member'
import moment from 'moment'


type PropsType = object

const MemberManagement: React.FC<PropsType> = () => {
  moment.locale('en')
  const navigate = useNavigate();
  const token = localStorage.getItem('id token')

  const [user, setUser] = useState<User>()

  const [alert, setAlert] = useState({
    severity: "",
    message: ""
  })
  const [openAlert, setOpenAlert] = useState(false)
  const [openWarning, setOpenWarning] = useState<boolean>(false)
  const [members, setMembers] = useState<IMember[]>([])
  const [openFormCreateMember, setOpenFormCreateMember] = useState<boolean>(false)
  const [formFields, setFormFields] = useState<IMemberParams>()

  const getAllMembers = useRequest(getMembers, {
    manual: true,
    onSuccess: (data) => {
      setMembers(data)
    }
  })
  
  const callAddMember = useRequest(addMember, {
    manual: true,
    onSuccess: (data) => {
      if (data.success) {
        getAllMembers.run()
      } else {
        setAlert({
          severity: 'error',
          message: 'Số thành viên của bạn đã đạt giới hạn cho phép!'
        })
        setOpenAlert(true)
      }
    },
    onError: (err) => {
      setAlert({
        severity: 'error',
        message: JSON.stringify(err)
      })
    }
  })


  const getUserByToken = useRequest(getUser, {
    manual: true,
    refreshDeps: [token],
    onSuccess: (data) => {
      if (data.user) {
        setUser(data.user)
        getAllMembers.run()
      } else {
        setAlert({
          severity: 'error',
          message: 'Đã xảy ra lỗi khi đăng nhập'
        })
        setOpenAlert(true)
      }
    },
    onError: (err) => {
      setAlert({
        severity: 'error',
        message: JSON.stringify(err)
      })
      navigate('/')
    }
  })

  useEffect(() => {
    getUserByToken.run(token || '')
    getAllMembers.run()
  }, [token])

  useEffect(() => {
    if (user) {
      if (user?.name == '' || user?.plan == '' || user?.contactNumber == '') {
        setOpenWarning(true)
      }
    }

  }, [user, setUser, token])

  const handleAddMember = () => {
    if (!!formFields?.name && !!formFields?.dob && !!formFields?.sex) {
      if (user) {
        callAddMember.run(user, formFields || {})
        setOpenFormCreateMember(false)
      }
    } else {
      setAlert({
        severity: 'error',
        message: 'Bạn nhập thiếu trường cần thiết'
      })
      setOpenAlert(true)
    }
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'left',
    minWidth: '29rem',
    margin: '1rem',
  }));

  return (
    <React.Fragment>
      <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity={alert.severity === "success" ? "success" : "error"} onClose={handleClose} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
      <Grid container>
        <Grid item xs={12}>
          <Grid container className={styles.container}>
            {getAllMembers.loading ? (
              <div style={{ margin: 'auto', marginTop: '20rem' }}>
                <CircularProgress />
              </div>
            ) : (
              <Box
                className={styles.subContainer}
                sx={{
                  '& .MuiTextField-root': { borderBottom: '1px solid #603c81', width: '30rem' },
                }}
              >
                {openWarning ? (
                  <Box className={styles.modal}>
                    <Typography>
                      Bạn cần điền các thông tin cần thiết để có thể thêm thông tin các thành viên của mình !
                    </Typography>
                    <Button onClick={() => navigate('/dang-ki-thong-tin')} className={styles.btn}>
                      OK
                    </Button>
                  </Box>
                ) : (
                  <Typography variant="h5" className={styles.title}>Quản lí thành viên</Typography>           
                )}
                <Grid item xs={12} className={styles.infoContainer}>
                  {members?.length > 0 && !openWarning ? (
                    <>
                      {members?.map((member, idx) => (
                        <Stack key={idx} spacing={{ xs: 3, sm: 2 }} className={styles.infoBox} direction="row" useFlexGap flexWrap="wrap">
                          <Item>
                            <BadgeIcon />
                            <Typography className={styles.textInfo}>Họ và tên: {member.name}</Typography>
                          </Item>
                          <Item>
                            <DateRangeIcon />
                            <Typography className={styles.textInfo}>Ngày sinh: {moment(member?.dob).format('MM-DD-YYYY')}</Typography>
                          </Item>
                          <Item>
                            {member?.sex === 'female' ? (
                              <FemaleIcon />
                            ) : (
                              <MaleIcon />
                            )}
                            {member?.sex ? (

                              <Typography className={styles.textInfo}>Giới tính: {member.sex === 'female'? 'Nữ':'Nam'}</Typography>
                            ):(
                              <Typography>-</Typography>
                            )}
                          </Item>
                          <Item>
                            <PermIdentityIcon />
                            <Typography className={styles.textInfo}>Số căn cước: {member.cccd}</Typography>
                          </Item>
                          <Item>
                            <LocalPhoneIcon />
                            <Typography className={styles.textInfo}>Số điện thoại: {member.contactNumber}</Typography>
                          </Item>
                          <Item>
                            <HomeIcon />
                            <Typography className={styles.textInfo}>Địa chỉ: {member.address}</Typography>
                          </Item>
                          <Item>
                            <EditNoteIcon />
                            <Typography className={styles.textInfo}>Ghi chú bệnh án: {member.note}</Typography>
                          </Item>
                        </Stack>
                      ))}
                    </>
                  ) : (
                    <Typography>Bạn chưa có thành viên nào!</Typography>
                  )}
                </Grid>
                <Grid>
                  <Button startIcon={<AddIcon />} className={styles.btn} disabled={openWarning} onClick={() => setOpenFormCreateMember(true)}>
                    Thêm thành viên
                  </Button>
                </Grid>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      <CreateMember
        formFields={formFields || {name: ''}}
        setFormFields={setFormFields}
        isModalOpen={openFormCreateMember} 
        setIsModalOpen={setOpenFormCreateMember}
        handleAddMember={handleAddMember}
      />
    </React.Fragment>
  )
}

export default MemberManagement