import { Box, CircularProgress, Grid  } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { io } from "socket.io-client";
import React, { useEffect, useState } from 'react'
import { User } from '../../models/user.model'
import styles from './styles.module.scss'
import { getUser } from '../../api/auth';
import { getRecords } from '../../api/medical-record';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useRequest } from 'ahooks';
import { IRecord } from '../../models/common.model';
// import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import BodyStatsAnalyze from './body-stats/index';
// import ReactPlayer from 'react-player';
import { getMembers } from '../../api/member-management';
import { IMember } from '../../models/member.model';

type PropsType = object

const RecordAnalyze: React.FC<PropsType> = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User>()
  const [latestRecord, setLatestRecord] = useState<IRecord>()
  const [tab, setTab] = useState<string>('stats');
  // const [videoName, setVideoName] = useState<string>('')
  const [records, setRecords] = useState<IRecord[]>()
  const [members, setMembers] = useState<IMember[]>([])

  const token = localStorage.getItem('id token')

  const baseUrl = process.env.REACT_APP_BASE_URL
  const socket = io(baseUrl || `http://${window.location.hostname}:${window.location.port}`);

  const getAllMembers = useRequest(getMembers, {
    manual: true,
    onSuccess: (data) => {
      setMembers(data)
    }
  })


  
  const getRecordsByUser = useRequest(getRecords, {
    manual: true,
    onSuccess: (data) => {
      setRecords(data)
    },
  })
  const getUserByToken = useRequest(getUser, {
    manual: true,
    refreshDeps: [token],
    onSuccess: (data) => {
      if (data.user) {
        setUser(data.user)
        getRecordsByUser.run(user?.userId || "")
      }
    },
    onError: () => {
      navigate('/dang-nhap')
    }
  })

  useEffect(() => {
    getUserByToken.run(token || '')
    getAllMembers.run()
  }, [token])
  
  useEffect(() => {
    socket.emit('get-record', user?.email);
    getRecordsByUser.run(user?.userId || "")
  }, [user, setUser])

  socket.on('new-record', data => {
    if (JSON.stringify(latestRecord) != JSON.stringify(data[0])) {
      setLatestRecord(data[0])
    }
  })

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
    getRecordsByUser.run(newValue)
  };

  // const handleChangeVideo = ( event: React.MouseEvent<HTMLElement>, value: string,) => {
  //   setVideoName(value)
  // }

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', width: '-webkit-fill-available' }}>
        <Grid item xs={12} className={styles.styleItemGrid}>
           <Box sx={{ width: '100%', typography: 'body1' }}>
            {/* <TabContext value={tab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Các chỉ số cơ thể" value="stats" />                                                                                                                                                                                                                                
                  <Tab label="Hình ảnh chuẩn đoán" value="image" />
                  <Tab label="Video nội soi" value="video" />
                  <Tab label="Âm thanh chuẩn đoán" value="audio" />
                </TabList>
              </Box>
              <TabPanel value="stats">
                <BodyStatsAnalyze records={records || []} />
              </TabPanel>
              <TabPanel value="image">
                <div>test</div>
              </TabPanel>
              <TabPanel value="video">
              <ToggleButtonGroup
                value={videoName}
                exclusive
                onChange={handleChangeVideo}
                aria-label="text alignment"
              >
                {latestRecord?.ears_videos && (
                  <ToggleButton value={latestRecord?.ears_videos} aria-label="left aligned">
                    <SlowMotionVideoIcon />
                    Nội soi tai
                  </ToggleButton>
                )}
                {latestRecord?.mouth_videos && (
                  <ToggleButton value={latestRecord?.mouth_videos} aria-label="left aligned">
                    <SlowMotionVideoIcon />
                    Nội soi miệng
                  </ToggleButton>
                )}
                {latestRecord?.nose_videos && (
                  <ToggleButton value={latestRecord?.nose_videos} aria-label="left aligned">
                    <SlowMotionVideoIcon />
                    Nội soi mũi
                  </ToggleButton>
                )}
                {latestRecord?.another_videos && (
                  <ToggleButton value={latestRecord?.another_videos} aria-label="left aligned">
                    <SlowMotionVideoIcon />
                    Nội soi các bộ phận khác
                  </ToggleButton>
                )}
              </ToggleButtonGroup>
              {videoName && (
                <div style={{position: 'relative', top: '5%', display: 'flex', justifyContent: 'center'}}>
                  <ReactPlayer
                    playing
                    controls
                    url={`http://localhost:5000/api/record/getRecord/?path=${videoName}`}
                  />
                </div>
              )}
              </TabPanel>
              <TabPanel value="audio">
              </TabPanel>
            </TabContext> */}
            {getAllMembers.loading ? (
                <CircularProgress color="inherit" />
              ) : (
                <TabContext value={tab || ""}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="Member'record management">
                      {members?.map((m) => (
                        <Tab key={m?.userId} label={m?.userId === user?.userId ? `${m?.name} (owner)` : m?.name} value={m?.userId} />
                      ))}
                    </TabList>
                  </Box>
                  {members?.map((m) => (
                    <TabPanel key={m?.userId} value={m?.userId || ''}>
                      {getRecordsByUser.loading ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          <BodyStatsAnalyze records={records || []} />
                        </Grid>
                      )}
                    </TabPanel>
                  ))}
                </TabContext>
              )}
          </Box>
        </Grid>
      </Box>
    </React.Fragment>
  )
}

export default RecordAnalyze