import React from 'react';
import Moment from 'moment';
import { Box, Grid, Stack, Paper, Typography } from '@mui/material'
import { IUpdateProfile } from '../../../api/profile';
import styles from './styles.module.scss'
import { styled } from '@mui/material/styles';
import BadgeIcon from '@mui/icons-material/Badge';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FemaleIcon from '@mui/icons-material/Female';
import MaleIcon from '@mui/icons-material/Male';
import HomeIcon from '@mui/icons-material/Home';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import FingerprintIcon from '@mui/icons-material/Fingerprint'
import EditNoteIcon from '@mui/icons-material/EditNote';

type PropsType = {
  formFields: IUpdateProfile,
}

const ConfirmInformation: React.FC<PropsType> = ({ formFields }) => {
  Moment.locale('en');
  // console.log(formFields)
  // console.log(Moment(formFields.dob).format('DD-MM-YYYY'))
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'left',
    minWidth: '22rem',
    margin: '1rem',
  }));
  
  return (
    <Grid container className={styles.container}>
      <Box
        className={styles.subContainer}
        sx={{
          '& .MuiTextField-root': { borderBottom: '1px solid #603c81', width: '30rem' },
        }}
      >
        <Typography variant="h5" className={styles.title}>Xác nhận thông tin</Typography>
        <Stack spacing={{ xs: 3, sm: 2 }} className={styles.infoBox} direction="row" useFlexGap flexWrap="wrap">
          <Item>
            <BadgeIcon />
            <Typography className={styles.textInfo}>Họ và tên: {formFields?.name}</Typography>
          </Item>
          <Item>
            <DateRangeIcon />
            <Typography className={styles.textInfo}>Ngày sinh: {formFields?.cccd}</Typography>
          </Item>
          <Item>
            {formFields?.sex === 'female' ? (
              <FemaleIcon />
            ) : (
              <MaleIcon />
            )}
            {formFields?.sex ? (

              <Typography className={styles.textInfo}>Giới tính: {formFields?.sex === 'female'? 'Nữ':'Nam'}</Typography>
            ):(
              <Typography>-</Typography>
            )}
          </Item>
          <Item>
            <PermIdentityIcon />
            <Typography className={styles.textInfo}>Số căn cước: {formFields?.cccd}</Typography>
          </Item>
          <Item>
            <LocalPhoneIcon />
            <Typography className={styles.textInfo}>Số điện thoại: {formFields?.contactNumber}</Typography>
          </Item>
          <Item>
            <EmailIcon />
            <Typography className={styles.textInfo}>Email: {formFields?.email}</Typography>
          </Item>
          <Item>
            <HomeIcon />
            <Typography className={styles.textInfo}>Địa chỉ: {formFields?.address}</Typography>
          </Item>
          {formFields?.userId && (
            <Item>
              <FingerprintIcon />
              <Typography className={styles.textInfo}>Mã định danh: {formFields?.userId}</Typography>
            </Item>
          )}
          <Item>
            <EditNoteIcon />
            <Typography className={styles.textInfo}>Ghi chú bệnh án: {formFields?.note}</Typography>
          </Item>
        </Stack>
      </Box>
    </Grid>
  )
}

export default ConfirmInformation