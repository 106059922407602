import { Alert, Grid, Snackbar } from '@mui/material'
import { useRequest } from 'ahooks';
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getUser } from '../../../api/auth';
import { User } from '../../../models/user.model';
import ConfirmInformation from '../confirm-information';

type PropsType = object

const UserInformation: React.FC<PropsType> = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('id token')

  const [user, setUser] = useState<User>()

  const [alert, setAlert] = useState({
    severity: "",
    message: ""
  })
  const [openAlert, setOpenAlert] = useState(false)

  const getUserByToken = useRequest(getUser, {

    manual: true,
    refreshDeps: [token],
    onSuccess: (data) => {
      if (data.user) {
        setUser(data.user)
      } else {
        setAlert({
          severity: 'error',
          message: 'Đã xảy ra lỗi khi đăng nhập'
        })
        setOpenAlert(true)
      }
    },
    onError: (err) => {
      setAlert({
        severity: 'error',
        message: JSON.stringify(err)
      })
      navigate('/dang-nhap')
    }
  })

  useEffect(() => {
    getUserByToken.run(token || '')
  }, [token])

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  }

  return (
    <React.Fragment>
      <Snackbar open={openAlert} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity={alert.severity === "success" ? "success" : "error"} onClose={handleClose} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
      <Grid container>
        <Grid item xs={12}>
          <ConfirmInformation formFields={user || {}} />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default UserInformation