import { Box, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { getUser } from '../../api/auth';
import { User } from '../../models/user.model';
import { useRequest } from 'ahooks';
type PropsType = object

const Home: React.FC<PropsType> = () => {
  const token = localStorage.getItem('id token')
  const navigate = useNavigate();
  const [user, setUser] = useState<User>()
  const getUserByToken = useRequest(getUser, {
    manual: true,
    refreshDeps: [token],
    onSuccess: (data) => {
      if (data.user) {
        setUser(data.user)
      }
    },
    onError: (err) => {
      console.log(err)
      navigate('/')
    }
  })
  useEffect(() => {
    getUserByToken.run(token || '')
  }, [token])

  return (
    <React.Fragment>
    <Box  sx={{ display: 'flex' }}>
      <Grid item xs={12} className={styles.styleItemGrid}>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ padding: '3rem' }}>
          
        </Grid>
      </Grid>
    </Box>
</React.Fragment>
  )
}

export default Home