
import axios, { AxiosRequestHeaders } from 'axios'
import { User } from '../models/user.model';
import { CREATE_MEMBER, GET_MEMBERS } from '../constant/api.constant';

export interface IMemberParams {
  name?: string,
  age?: number,
  sex?: string,
  address?: string,
  contactNumber?: string,
  cccd?: string,
  note?: string,
  dob?: Date
}


export const loadToken = () => {
  const token = localStorage.getItem('id token')
  return token
}

export const addMember = async(user: User, fieldInfo: IMemberParams) => {
  const token = loadToken()
  const params = {...fieldInfo, userId: user?.userId, email: user?.email }
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token,
  } as AxiosRequestHeaders
  try {
    const response = await axios.post(CREATE_MEMBER, params, { headers: headers })
    return response.data
  } catch (error) {
    return error
  }
}

export const getMembers = async() => {
    const token = loadToken()
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': token,
    } as AxiosRequestHeaders
    try {
      const response = await axios.get(GET_MEMBERS, { headers: headers })
      if (response.data.success) {
        return response.data.user
      }
    } catch (error) {
      return error
    }
  }
