import { Box, CircularProgress, Grid } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { getUser } from "../../api/auth";
import { User } from "../../models/user.model";
import { useRequest } from "ahooks";
type PropsType = object;

const GoogleSuspense: React.FC<PropsType> = () => {
  const token = localStorage.getItem("id token");
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState<User>();
  const getUserByToken = useRequest(getUser, {
    manual: true,
    refreshDeps: [token],
    onSuccess: (data) => {
      if (data.user) {
        setUser(data.user);
        navigate("/dang-ki-thong-tin");
      }
    },
    onError: (err) => {
      console.log(err);
      navigate("/dang-nhap");
    },
  });
  useEffect(() => {
    getUserByToken.run(token || "");
  }, [token]);

  useEffect(() => {
    const token = `Bearer ${location.search.split("=")[1]}`;
    if (token) {
      getUserByToken.run(token);
      localStorage.setItem('id token', token)
    }
  }, [location]);
  return (
    <React.Fragment>
      <Box sx={{ display: "flex" }}>
        <Grid item xs={12} className={styles.styleItemGrid}>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ padding: "3rem" }}
          >
            <CircularProgress color="inherit" />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default GoogleSuspense;
