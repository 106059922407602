import { Alert, Snackbar } from "@mui/material";
import { useRequest } from "ahooks";
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { getUser } from "../api/auth";
import Sidebar from "../common/sidebar";
import { User } from "../models/user.model";
import { routerType } from "../types/router.types";
import pagesData from "./router-components";

const Router = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User>()
  const [alert, setAlert] = useState({
    severity: "info",
    message: "",
  })
  const [viewFullScreen, setViewFullScreen] = useState<boolean>(true)
  const [openAlert, setOpenAlert] = useState(false)
  const token = localStorage.getItem('id token')

  const getUserByToken = useRequest(getUser, {
    ready: !!token,
    refreshDeps: [token],
    onSuccess: (data) => {
      if (data.user) {
        setUser(data.user)
      } else {
        navigate('/dang-nhap')
      }
    },
    onError: (err) => {
      setAlert({
        severity: 'error',
        message: JSON.stringify(err)
      })
    }
  })

  useEffect(() => {
    if (token) {
      getUserByToken.run(token)
    }
  }, [token])

  const pageRoutes = pagesData.map(({ path, title, element }: routerType) => {
    return <Route key={title} path={`/${path}`} element={element} />;
  });

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  }

  return (
    <>
      <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert onClose={handleClose} severity={alert.severity === 'success'? 'success':'error'} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
      <Sidebar user={user} openDrawer={viewFullScreen} setOpenDrawer={setViewFullScreen} />
      <Routes>{pageRoutes}</Routes>
    </>
  );
};

export default Router;