import React, { useEffect, useState } from 'react'
import { Box, Grid, CardContent, Typography, TextField, RadioGroup, FormControlLabel, Radio, FormLabel } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { IUpdateProfile } from '../../../api/profile';
import styles from './styles.module.scss'

type PropsType = {
  formFields: IUpdateProfile,
  setFormFields: (data: IUpdateProfile) => void
}

const UpdateInformation: React.FC<PropsType> = ({ formFields, setFormFields }) => {
  const [dob, setDOB] = useState<string>()

  useEffect(() => {
    console.log(dob)
  }, [dob, setDOB])

  const getFormFieldsData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldValue = e.target.value
    const fieldName = e.target.name
    setFormFields({...formFields, [fieldName]: fieldValue})
  }

  return (
    <Grid container className={styles.container}>
      <Box
        className={styles.subContainer}
        sx={{
          '& .MuiTextField-root': { borderBottom: '1px solid #603c81', width: '30rem' },
        }}
      >
        <Typography variant="h5" className={styles.title}>Cập nhật thông tin</Typography>
        <form autoComplete='off'>
          <CardContent>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className={styles.formContainer}>
                <Grid item xs={12} className={styles.inputContainer}>
                  <TextField
                    variant="standard"
                    label="Tên người dùng"
                    name="name"
                    value={formFields?.name}
                    onChange={getFormFieldsData}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} className={styles.inputContainer}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Ngày sinh"
                      format="DD/MM/YYYY"
                      onChange={(value: Date | null) => setFormFields({...formFields, dob: value || undefined})}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={8} className={styles.inputContainer}>
                  <FormLabel id="demo-radio-buttons-group-label">Giới tính*</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="F"
                    name="sex"
                    onChange={getFormFieldsData}
                    className={styles.planContainer}
                  >
                    <FormControlLabel value="male" control={<Radio />} label="Nam" />
                    <FormControlLabel value="female" control={<Radio />} label="Nữ" />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} className={styles.inputContainer}>
                  <TextField
                    variant="standard"
                    label="Căn cước công dân"
                    name="cccd"
                    value={formFields.cccd}
                    onChange={getFormFieldsData}
                  />
                </Grid>
                
                <Grid item xs={12} className={styles.inputContainer}>
                  <TextField
                    variant="standard"
                    name="email"
                    value={formFields.email}
                    onChange={getFormFieldsData}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} className={styles.inputContainer}>
                  <TextField
                    variant="standard"
                    label="Địa chỉ"
                    name="address"
                    value={formFields.address}
                    onChange={getFormFieldsData}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} className={styles.inputContainer}>
                  <TextField
                    variant="standard"
                    label="Số điện thoại"
                    name="contactNumber"
                    value={formFields.contactNumber}
                    onChange={getFormFieldsData}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} className={styles.inputContainer}>
                  <TextField
                    variant="standard"
                    label="Lịch sử bệnh án"
                    name="note"
                    value={formFields.note}
                    onChange={getFormFieldsData}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="F"
                    name="plan"
                    onChange={getFormFieldsData}
                    className={styles.planContainer}
                  >
                    <FormControlLabel value="F" control={<Radio />} label="Free ( Bạn được phép đăng kí cho 4 người) " />
                    <FormControlLabel value="G" control={<Radio />} label="Gold ( Bạn được phép đăng kí cho 8 người)" />
                    <FormControlLabel value="E" control={<Radio />} label="Enterprise ( Bạn được phép đăng kí cho 20 người)" />
                  </RadioGroup>
                </Grid>
            </Grid>
          </CardContent>
        </form>
      </Box>
    </Grid>
  )
}

export default UpdateInformation