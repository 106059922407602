
import axios, { AxiosRequestHeaders } from 'axios'
import { User } from '../models/user.model';
import { UPDATE_USER } from '../constant/api.constant';

interface Callback {
(data: User | undefined, err: Error | string | undefined): void
}

export interface IUpdateProfile {
  userId?: string,
  name?: string,
  age?: number,
  sex?: string,
  email?: string,
  address?: string,
  contactNumber?: string,
  cccd?: string,
  note?: string,
  plan?: string,
  dob?: Date
}


export const loadToken = () => {
  const token = localStorage.getItem('id token')
  return token
}
  
export const updateProfile = async (email: string, params: IUpdateProfile, callback: Callback) => {
  const token = loadToken()
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token,
  } as AxiosRequestHeaders
  try {
    const response = await axios.put(UPDATE_USER, params, { headers: headers })
    if (response.data.success) {
      callback(response.data.user, undefined)
    } else {
      callback(undefined, response.data.message)
    }
  } catch (error) {
    callback(undefined, error as string)
  }
}
