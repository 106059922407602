import React from 'react'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

type PropsType = {
  title: string;
  labels: string[];
  data: number[];
  borderColor?: string;
  backgroundColor?: string;
  pointBorderColor?: string;
  pointRadius?: number
}

const LineChart: React.FC<PropsType> = ({ title, labels, data, borderColor, backgroundColor, pointBorderColor, pointRadius}) => {
  return (
    <Line
      options={{
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: title,
          },
        },
      }}
      data={{
        labels: labels,
        datasets: [{
          label: title,
          data: data,
          borderColor: borderColor || 'black',
          backgroundColor: backgroundColor || 'black',
          pointBorderColor: pointBorderColor || 'black',
          pointRadius: pointRadius || 3,
        }]
      }}
      style={{ width: '100%'}}
    ></Line>
  )
}

export default LineChart