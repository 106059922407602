
import axios, { AxiosRequestHeaders } from 'axios'
import { GET_RECORDS, GET_DIRECTORY } from '../constant/api.constant';

export const loadToken = () => {
  const token = localStorage.getItem('id token')
  return token
}
  
export const getRecords = async (userId: string) => {
  const token = loadToken()
  const params = {
    userId: userId
  }
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token,
  } as AxiosRequestHeaders
  try {
    const response = await axios.get(GET_RECORDS, { params: params, headers: headers })
    if (response.data.success) {
      return response.data.records
    } else {
      return response.data.error
    }
  } catch (error: any) {
    return error
  }
}

export const getFileFromDirectory = async (recordId: string) => {
  const token = loadToken()
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token,
  } as AxiosRequestHeaders
  try {
    const response = await axios.get(`${GET_DIRECTORY}/?recordId=${recordId}`, { headers: headers })
    return response.data.images
  } catch (error: any) {
    return error
  }
}


