const baseUrl = process.env.REACT_APP_BASE_URL;

export const REGISTER_URL = `${baseUrl}/api/account/register`
export const AUTHENTICATE_URL = `${baseUrl}/api/account/authenticate`

export const UPDATE_USER = `${baseUrl}/api/user/update`
export const CREATE_MEMBER = `${baseUrl}/api/user/create-member`
export const GET_MEMBERS = `${baseUrl}/api/user/get-members`

export const GET_RECORDS = `${baseUrl}/api/record`
export const GET_DIRECTORY = `${baseUrl}/api/record/get-directory`
